import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

/* eslint-disable jsx-a11y/label-has-associated-control */

/* eslint-disable jsx-a11y/no-autofocus */
import { AccountErrorCode } from "@saleor/sdk";
import { Checkbox } from "@app/components/atoms";
import "./scss/styles.scss";
import eyeImg from "@temp/images/ecommerce/login/eye.svg";
import eyeCloseImg from "@temp/images/ecommerce/login/eyeClose.svg";
import { commonMessages, getErrorMessage, logInMessages } from "@temp/intl";
import React from "react";
import { useIntl } from "react-intl";
import ReactSVG from "react-svg";
import { animated, useSpring } from "react-spring";
import { AlertShowIframe, AlertShowInformation } from "@temp/components";
export var LoginValidateCode = function LoginValidateCode(_ref) {
  var accessCodeSeconds = _ref.accessCodeSeconds,
      seeCode = _ref.seeCode,
      errorsValidateCode = _ref.errorsValidateCode,
      code = _ref.code,
      resendCode = _ref.resendCode,
      siteSettings = _ref.siteSettings,
      userHasAcceptedTyc = _ref.userHasAcceptedTyc,
      userWaNumber = _ref.userWaNumber,
      handleChange = _ref.handleChange,
      handleCodeInput = _ref.handleCodeInput,
      handleSeeCode = _ref.handleSeeCode,
      handleValidateCode = _ref.handleValidateCode,
      handleReSendCode = _ref.handleReSendCode;
  var intl = useIntl();

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      tycState = _React$useState2[0],
      setTycState = _React$useState2[1];

  var _React$useState3 = React.useState(false),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      ptdState = _React$useState4[0],
      setPtdState = _React$useState4[1];

  var _React$useState5 = React.useState(false),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      showAlertInformation = _React$useState6[0],
      setShowAlertInformation = _React$useState6[1];

  var _React$useState7 = React.useState(false),
      _React$useState8 = _slicedToArray(_React$useState7, 2),
      showTycAnimation = _React$useState8[0],
      setShowTycAnimation = _React$useState8[1];

  var _React$useState9 = React.useState(false),
      _React$useState10 = _slicedToArray(_React$useState9, 2),
      showPtdAnimation = _React$useState10[0],
      setShowPtdAnimation = _React$useState10[1];

  var _React$useState11 = React.useState(true),
      _React$useState12 = _slicedToArray(_React$useState11, 2),
      activeCount = _React$useState12[0],
      setActiveCount = _React$useState12[1];

  var _React$useState13 = React.useState(accessCodeSeconds),
      _React$useState14 = _slicedToArray(_React$useState13, 2),
      countStart = _React$useState14[0],
      setCountStart = _React$useState14[1];

  var _React$useState15 = React.useState(accessCodeSeconds),
      _React$useState16 = _slicedToArray(_React$useState15, 2),
      seconds = _React$useState16[0],
      setSeconds = _React$useState16[1];

  var _React$useState17 = React.useState(new Date()),
      _React$useState18 = _slicedToArray(_React$useState17, 2),
      sendCodeTime = _React$useState18[0],
      setSendCodeTime = _React$useState18[1];

  var _React$useState19 = React.useState({
    x: 0,
    y: 0
  }),
      _React$useState20 = _slicedToArray(_React$useState19, 2),
      tycPosition = _React$useState20[0],
      setTycPosition = _React$useState20[1];

  var _React$useState21 = React.useState(false),
      _React$useState22 = _slicedToArray(_React$useState21, 2),
      showTycIframe = _React$useState22[0],
      setShowTycIframe = _React$useState22[1];

  var _React$useState23 = React.useState(false),
      _React$useState24 = _slicedToArray(_React$useState23, 2),
      showPtdIframe = _React$useState24[0],
      setShowPtdIframe = _React$useState24[1];

  var scaleButton = useSpring({
    config: {
      friction: 40,
      mass: 5,
      tension: 350
    },
    from: {
      scale: 1.1
    },
    loop: true,
    to: {
      scale: 1
    }
  });
  React.useEffect(function () {
    if (tycState && showTycAnimation) {
      setShowTycAnimation(false);
    }

    if (ptdState && showPtdAnimation) {
      setShowPtdAnimation(false);
    }
  }, [tycState, ptdState]);

  var resetCount = function resetCount(amountOfSeconds) {
    setSendCodeTime(new Date());
    setCountStart(amountOfSeconds);
    setSeconds(amountOfSeconds);
    setActiveCount(function (active) {
      return !active;
    });
  };

  React.useEffect(function () {
    var processTimer = function processTimer() {
      var currentTime = new Date();
      var secondsBetweenDates = (currentTime.getTime() - sendCodeTime.getTime()) / 1000;
      var currentSeconds = countStart - Math.floor(secondsBetweenDates);
      setSeconds(currentSeconds > 0 ? currentSeconds : 0);

      if (currentSeconds > 0) {
        setSeconds(currentSeconds > 0 ? currentSeconds : 0);
      } else if (currentSeconds <= 0) {
        clearInterval(time);
      }
    };

    var time = setInterval(function () {
      processTimer();
    }, 1000);
    return function () {
      return clearInterval(time);
    };
  }, [activeCount, countStart]);
  React.useEffect(function () {
    if (errorsValidateCode.length > 0) {
      errorsValidateCode.forEach(function (error) {
        if ((error === null || error === void 0 ? void 0 : error.code) === AccountErrorCode.ECOMMERCE_WEB_ACCESS_MAX_ATTEMPTS) {
          resetCount(error.secondsToUnlock);
        }
      });
    } else {
      resetCount(accessCodeSeconds);
    }
  }, [errorsValidateCode, resendCode]);
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "buttonChange",
    onClick: handleChange
  }, React.createElement("p", null, intl.formatMessage(commonMessages.change))), React.createElement("div", {
    className: "validateCode"
  }, React.createElement("label", null, intl.formatMessage(logInMessages.codeReceivedInWA), " : "), React.createElement("div", {
    className: "validateCode__input"
  }, React.createElement("input", {
    autoFocus: true,
    type: seeCode ? "tel" : "password",
    value: code,
    onChange: function onChange(e) {
      return handleCodeInput(e.target.value);
    }
  }), React.createElement("div", {
    onClick: handleSeeCode
  }, seeCode ? React.createElement("div", {
    className: "validateCode__eye"
  }, React.createElement(ReactSVG, {
    path: eyeImg
  })) : React.createElement("div", {
    className: "validateCode__eye"
  }, React.createElement(ReactSVG, {
    path: eyeCloseImg
  })))), errorsValidateCode.length > 0 && React.createElement("div", {
    className: "validateCode__error"
  }, errorsValidateCode.map(function (error, index) {
    return React.createElement("p", {
      key: "".concat(index, "-errorValidateCodeCustomer")
    }, getErrorMessage(error.code, intl, {
      minutesToUnlock: (error === null || error === void 0 ? void 0 : error.secondsToUnlock) ? (error === null || error === void 0 ? void 0 : error.secondsToUnlock) / 60 + 1 : 1
    }));
  })), !userHasAcceptedTyc && !window.location.pathname.includes("/deliveryman") && React.createElement("div", {
    className: "validateCode__checkbox"
  }, React.createElement("div", {
    className: "validateCode__checkbox__container"
  }, React.createElement(Checkbox, {
    name: "tyc",
    onChange: function onChange() {
      return setTycState(function (tyc) {
        return !tyc;
      });
    },
    animation: showTycAnimation,
    checked: tycState
  }), React.createElement("p", {
    id: "acceptedTyc"
  }, intl.formatMessage(logInMessages.acceptTyc, {
    tyc: React.createElement("span", {
      onClick: function onClick() {
        if (siteSettings === null || siteSettings === void 0 ? void 0 : siteSettings.linkTermsAndConditionsCustomer) {
          setShowTycIframe(true);
          if (showPtdAnimation) setShowPtdAnimation(false);
        }
      }
    }, intl.formatMessage(commonMessages.tyc))
  }))), React.createElement("div", {
    className: "validateCode__checkbox__container"
  }, React.createElement(Checkbox, {
    name: "ptd",
    onChange: function onChange() {
      return setPtdState(function (ptd) {
        return !ptd;
      });
    },
    animation: showPtdAnimation,
    checked: ptdState
  }), React.createElement("p", null, intl.formatMessage(logInMessages.acceptPtd, {
    ptd: React.createElement("span", {
      onClick: function onClick() {
        if (siteSettings === null || siteSettings === void 0 ? void 0 : siteSettings.linkPrivacyPolicyCustomer) {
          setShowPtdIframe(true);
          if (showTycAnimation) setShowTycAnimation(false);
        }
      }
    }, intl.formatMessage(commonMessages.ptd))
  }))))), React.createElement("div", {
    className: "validateCode__buttons"
  }, React.createElement("div", {
    className: "validateCode__resendCode ".concat(seconds > 0 ? "validateCode__resendCode__disabled" : ""),
    onClick: function onClick() {
      if (seconds > 0) return;
      handleReSendCode(userWaNumber);
    }
  }, React.createElement("p", null, intl.formatMessage(logInMessages.resendCode, {
    time: seconds > 0 ? "(".concat(seconds, ")") : ""
  }))), React.createElement(animated.input, {
    style: scaleButton,
    type: "submit",
    className: "validateCode__button",
    onClick: function onClick(e) {
      if (!userHasAcceptedTyc && (!tycState || !ptdState) && !window.location.pathname.includes("/deliveryman")) {
        e.preventDefault();
        var position = document.getElementById("acceptedTyc").getBoundingClientRect();
        setTycPosition({
          x: position.x,
          y: position.y
        });
        setShowTycAnimation(false);
        setShowPtdAnimation(false);
        setShowAlertInformation(true);
      } else if (!window.location.pathname.includes("/deliveryman")) {
        handleValidateCode(e, code, userHasAcceptedTyc || tycState && ptdState);
      } else {
        handleValidateCode(e, code);
      }
    },
    value: intl.formatMessage(logInMessages.validateCode)
  })), showAlertInformation && React.createElement(AlertShowInformation, {
    message: !tycState && !ptdState ? intl.formatMessage(commonMessages.acceptTycAndPtd) : !tycState ? intl.formatMessage(commonMessages.acceptTyc) : !ptdState ? intl.formatMessage(commonMessages.acceptPtd) : "",
    hideModal: function hideModal() {
      if (!tycState && !showTycAnimation) setShowTycAnimation(true);
      if (!ptdState && !showPtdAnimation) setShowPtdAnimation(true);
      window.scrollTo(tycPosition.x, tycPosition.y);
      setShowAlertInformation(false);
    },
    errors: []
  }), (showPtdIframe || showTycIframe) && React.createElement(AlertShowIframe, {
    buttonLabel: intl.formatMessage(logInMessages.alreadyReadIt),
    hideModal: function hideModal() {
      if (showPtdIframe) setShowPtdIframe(false);
      if (showTycIframe) setShowTycIframe(false);
    },
    iframeSrc: showTycIframe ? siteSettings === null || siteSettings === void 0 ? void 0 : siteSettings.linkTermsAndConditionsCustomer : showPtdIframe ? siteSettings === null || siteSettings === void 0 ? void 0 : siteSettings.linkPrivacyPolicyCustomer : "",
    title: showTycIframe ? intl.formatMessage(commonMessages.tyc) : showPtdIframe ? intl.formatMessage(commonMessages.ptd) : "",
    titleIframe: showTycIframe ? "termsAndConditions" : showPtdIframe ? "privacyPolicy" : ""
  }));
};
LoginValidateCode.displayName = "LoginValidateCode";